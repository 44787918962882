import React, {useRef, useState} from 'react';
import {Form} from 'react-bootstrap';

import DatePicker from 'react-datepicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import {format, isAfter, isBefore, isValid, parse, addDays} from 'date-fns';
import {enAU} from 'date-fns/locale';

import './date-range.scss';

const displayDateFormat = 'dd/MM/yyyy';
const datasetDateFormat = 'yyyy-MM-dd';

export default function DateRange(props) {
  const [startDate, setStartDate] = useState(props.selectedDateRangeFrom);
  const [endDate, setEndDate] = useState(props.selectedDateRangeTo);
  const [fieldErrorStartDate, setFieldErrorStartDate] = useState(null);
  const [fieldErrorEndDate, setFieldErrorEndDate] = useState(null);

  const inputStartDateRef = useRef();
  const inputEndDateRef = useRef();
  

  const getMinDate = () => {
    return props.minDate
      ? parse(props.minDate, datasetDateFormat, new Date())
      : new Date();
  }
  const minDate = getMinDate();

  const getMaxDate = () => {
    let maxDate;

    if (typeof props.maxDate === 'number') {      
      maxDate = addDays(new Date(), props.maxDate);
    } else if (typeof props.maxDate === 'string') {
      maxDate = parse(props.maxDate, datasetDateFormat, new Date());
    } else {
      maxDate = new Date();
    }

    return maxDate;
  }
  const maxDate = getMaxDate();

  const handleStartDateChange = (date) => {
    if ((date === null && props.selectedDateRangeTo === null)) {
      return;  
    } 
    
    if (!isValid(date) || isBefore(date, minDate) || isAfter(date, maxDate)) {
      setFieldErrorStartDate("The 'from' date needs to be a valid date between " +
        format(minDate, displayDateFormat) + ' and ' + format(maxDate, displayDateFormat));
    } else if (props.selectedDateRangeTo !== null && isAfter(date, props.selectedDateRangeTo)) {
      setFieldErrorStartDate("The 'from' date needs to be before the 'to' date.");
    } else {
      setFieldErrorStartDate(null);
      setStartDate(date);
      props.onDateRangeChange({
        from: date,
        to: props.selectedDateRangeTo
      });
      
      if (inputStartDateRef.current.input.value.length === 10) {
        inputStartDateRef.current.setOpen(false);
        inputEndDateRef?.current?.setFocus();
      }
    }
  }
  
  const handleStartDateBlur = (event) => {
    const fromString = props.selectedDateRangeFrom ? props.selectedDateRangeFrom : '';
    if (event.target.value !== fromString) {
      if (event.target.value === '') {
        setFieldErrorStartDate(null);
        setFieldErrorEndDate(null);
        props.onDateRangeChange({from: null, to: null});
        return;
      }

      handleStartDateChange(parse(event.target.value, displayDateFormat, new Date()));
    }
  }
  
  const handleEndDateChange = (date) => {
    if (date === null && props.selectedDateRangeFrom === null) {
      return;
    }
    
    if (!isValid(date) || isBefore(date, minDate) || isAfter(date, maxDate)) {
      setFieldErrorEndDate("The 'to' date needs to be a valid date between " +
        format(minDate, displayDateFormat) + ' and ' + format(maxDate, displayDateFormat));
    } else if (isBefore(date, props.selectedDateRangeFrom)) {
      setFieldErrorEndDate("The 'to' date needs to be after the 'from' date.");
    } else {
      setFieldErrorEndDate(null);
      setEndDate(date);
      props.onDateRangeChange({
        from: props.selectedDateRangeFrom,
        to: date
      });
    }
  }

  const handleEndDateBlur = (event) => {
    const toString = props.selectedDateRangeTo ? props.selectedDateRangeTo : '';
    if (event.target.value !== toString) {
      if (event.target.value === '') {
        setFieldErrorEndDate(null);
        props.onDateRangeChange({from: props.selectedDateRangeFrom, to: null});
        return;
      }
  
      handleEndDateChange(parse(event.target.value, displayDateFormat, new Date()));
    }
  }
  
  return (
      <Form.Group className="date-range">
        <Form.Label column={false}>Select date range:</Form.Label>
        <br/>
        <div>
          <div className={'date-picker-input'}>
            <DatePicker
              ref={inputStartDateRef}
              placeholderText={"From " + displayDateFormat.toLowerCase()}
              dateFormat={displayDateFormat}
              strictParsing={true}
              className="form-control"
              locale={enAU}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
              autoComplete="false"
              selected={startDate}
              onSelect={() => inputEndDateRef?.current?.setFocus()}
              onChange={handleStartDateChange}
              onBlur={handleStartDateBlur}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={endDate ? endDate : maxDate}
            />
          </div>
          {' – '}
          <div className={'date-picker-input'}>
            <DatePicker
              ref={inputEndDateRef}
              placeholderText={"To " + displayDateFormat.toLowerCase()}
              dateFormat={displayDateFormat}
              strictParsing={true}
              className="form-control"
              locale={enAU}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
              disabledKeyboardNavigation
              selected={endDate}       
              onChange={handleEndDateChange}
              onBlur={handleEndDateBlur}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate ? startDate : minDate}
              maxDate={maxDate}
            />
          </div>
        </div>
        {fieldErrorStartDate ? (
          <p key="fieldErrorFrom" className="text-danger">{fieldErrorStartDate}</p>
        ) : (
          ''
        )}
        {fieldErrorEndDate ? (
          <p key="fieldErrorTo" className="text-danger">{fieldErrorEndDate}</p>
        ) : (
          ''
        )}
        <p className="text-info small">
          <FontAwesomeIcon className="info-icon" icon={faInfoCircle}/>
          For the selected data collection "{props.dataCollectionName}" the
          dates need to be between {format(minDate, displayDateFormat) + ' and ' + 
          format(maxDate, displayDateFormat)} (time zone: {' '}{props.timeZone}).
        </p>
      </Form.Group>
  );
};
